<template>
  <v-container class="contactInfo" fluid fill-height pa-0>
    <v-row no-gutters class="register-page h-100 w-100 justify-center align-center flex-column">
      <div class="d-flex w-100 header-bar">
        <v-row>
          <v-col cols="4" class="pa-0 d-flex align-center back-box">
            <div @click="goToBackPage" class="back-div">
              <v-icon color="black" class="back-icon">mdi-arrow-left</v-icon>
              <span class="back-text">Back</span>
            </div>
          </v-col>
          <v-col cols="4" class="d-flex justify-center align-center pa-0">
            <div class="logo-div">
              <img src="@/assets/svg/logo-colored.svg" class="logo" />
            </div>
          </v-col>
          <v-col cols="4">
            <div class="d-flex justify-end align-center mr-4">
                <v-select
                  class="notranslate"
                  v-model="currentLanguage"
                  :items="$hyper.languages"
                  item-text="name"
                  item-value="code"
                  label="Language"
                  return-object
                  solo
                  @select="changeLanguage"
                  flat
                  style="max-width:200px; height:50px; z-index: 99"
                  ref="languageSelector"
                >
                    <template v-slot:item="{ item }">
                      <v-list-item class="notranslate" @click="changeLanguage(item)">
                        {{ item.name }}
                      </v-list-item>
                    </template>
                  </v-select>
            </div>
          </v-col>
        </v-row>
      </div>
      <v-col class="center-alignment" col-xs="12" col-sm="12" col-md="5" col-lg="8" col-xl="8">
        <v-card class="register-form-wrapper text-center" elevation="0">
          <v-row class="flex-column h-100 ma-auto pa-2">
            <v-row class="justify-center body-content">
              <v-col>
                <v-form @submit="submitForm">
                  <p class="register-text">Tell us about your business.</p>
                  <v-row class="ma-auto pt-2 pb-3">
                    <div class="sub-title d-flex align-center">
                      <span class="pr-3 pt-4 pb-5 capital-text"> ABOUT YOUR BUSINESS </span>
                      <div class="line" />
                    </div>
                  </v-row>
                  <v-row class="ma-auto justify-center mb-6">
                    <Upload
                      mode="justImage"
                      @change="setProfilePicture"
                      :acceptedFiles="'.jpg,.jpeg,.png'"
                    >
                      <div class="d-flex ma-auto profile-image-container cursor-pointer">
                        <v-img
                          class="profile-image"
                          :class="{ 'image-background': getIcon.indexOf('plus') >= 0 }"
                          :src="profilePicture"
                          alt="Profile"
                        />
                        <div class="profile-image-overlay" :class="{ profilePicture: avatar }">
                          <v-img :src="getIcon" />
                        </div>
                      </div>
                    </Upload>
                  </v-row>
                  <v-row class="flex-column align-center ma-auto">
                    <v-col>
                      <v-row
                        class="flex-column ma-auto"
                        v-for="(item, j) in info"
                        :key="`item${j}`"
                      >
                        <v-row
                          class="flex-column align-baseline"
                          v-if="item.name === 'name' || item.name === 'website'"
                        >
                          <label class="label-title">{{ item.placeholder }}*</label>
                          <BaseInput
                            background-color="#ffff"
                            class="mr-2 contact-input"
                            solo
                            dense
                            flat
                            :name="businessInfo[item.name]"
                            v-model.trim="businessInfo[item.name]"
                            :status="errors(item.name).length ? 'error' : 'normal'"
                            :error-messages="errors(item.name)"
                            @input="$v.businessInfo[item.name].$touch()"
                            @blur="$v.businessInfo[item.name].$touch()"
                          />
                        </v-row>
                        <v-row class="flex-column align-baseline">
                          <div
                            class="sub-title d-flex align-center pt-10"
                            v-if="item.name === 'customer'"
                          >
                            <span class="pr-3 pt-4 pb-6 capital-text"> ABOUT YOUR CUSTOMER </span>
                            <div class="line" />
                          </div>
                        </v-row>
                        <v-row
                          class="flex-column align-baseline"
                          v-if="item.name === 'service' || item.name === 'customer'"
                        >
                          <label class="label-title">{{ item.placeholder }}*</label>
                          <v-radio-group
                            row
                            v-model="businessInfo[item.name]"
                            :status="errors(item.name).length ? 'error' : 'normal'"
                            :error-messages="errors(item.name)"
                            @input="$v.businessInfo[item.name].$touch()"
                            @blur="$v.businessInfo[item.name].$touch()"
                          >
                            <div
                              v-for="(option, index) in sources[item.name]"
                              :key="index"
                              class="input-field"
                            >
                              <v-radio :label="option" :value="option" color="info"> </v-radio>
                            </div>
                          </v-radio-group>
                          <BaseInput
                            :class="{ 'border-red': otherServiceErr }"
                            class="contact-input other-input-field"
                            background-color="#ffff"
                            solo
                            dense
                            flat
                            v-model.trim="otherService"
                            :name="businessInfo[item.name]"
                            v-if="businessInfo.service === 'Other' && item.name === 'service'"
                          />
                          <div
                            class="other-input-field-error v-text-field__details"
                            v-if="
                              otherServiceErr &&
                                businessInfo.service === 'Other' &&
                                item.name === 'service'
                            "
                          >
                            <div class="v-messages theme--light error--text" role="alert">
                              <div class="v-messages__wrapper">
                                <div class="v-messages__message">
                                  Please provide Service.
                                </div>
                              </div>
                            </div>
                          </div>
                        </v-row>
                        <v-row
                          class="flex-column align-baseline age-field"
                          v-if="item.name === 'age'"
                        >
                          <label class="label-title mb-1">{{ item.placeholder }}*</label>
                          <span class="gray-text">Check all that apply</span>
                          <CustomCheckboxGroup
                            :noErrorIcon="true"
                            :options="sources[item.name]"
                            name="ages"
                            item-text="name"
                            item-value="name"
                            v-model="businessInfo.age"
                            :status="errors(item.name).length ? 'error' : 'normal'"
                            :error-messages="errors(item.name)"
                            @input="$v.businessInfo[item.name].$touch()"
                            @blur="$v.businessInfo[item.name].$touch()"
                          >
                          </CustomCheckboxGroup>
                        </v-row>
                        <v-row class="flex-column align-baseline" v-if="item.name === 'locations'">
                          <label class="label-title d-flex align-center"
                            >{{ item.placeholder }}*
                          </label>
                          <div class="w-100">
                            <div
                              v-for="(location, index) in locations"
                              :key="location.key"
                              class="contact-input location-input
                              v-input input-field v-input--is-label-active v-input--is-dirty
                              v-input--dense theme--light v-text-field v-text-field--single-line
                              v-text-field--solo v-text-field--solo-flat v-text-field--is-booted
                              v-text-field--enclosed errorState"
                              :class="{
                                'errorState v-input--has-state error--text': locationErrors,
                              }"
                            >
                              <div class="v-input__control">
                                <div class="v-input__slot">
                                  <div class="v-text-field__slot">
                                    <vue-google-autocomplete
                                      :id="location.key"
                                      placeholder="Start typing..."
                                      v-on:inputChange="(data) => setLocation(data, location.key)"
                                      :types="['establishment']"
                                      :component-restrictions="null"
                                    >
                                    </vue-google-autocomplete>
                                  </div>
                                </div>
                              </div>
                              <v-icon
                                v-if="canShow(index)"
                                class="close cursor-pointer"
                                @click="removeLocation(location.key)"
                                >mdi-close</v-icon
                              >
                            </div>
                          </div>
                          <div class="v-text-field__details" v-if="locationErrors">
                            <div class="v-messages theme--light error--text" role="alert">
                              <div class="v-messages__wrapper">
                                <div class="v-messages__message">
                                  Please provide valid location
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="addLocation" @click="addLocation">+ Add Location</div>
                        </v-row>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-divider class="register-divider" />
                  <div class="justify-center register-button-grid">
                    <v-btn
                      class="register-button btn-purple"
                      color="info"
                      type="submit"
                      block
                      :disabled="($v.$dirty && $v.$invalid) || isSavingData"
                      :loading="isSavingData"
                    >
                      Complete Profile</v-btn
                    >
                  </div>
                </v-form>
              </v-col>
            </v-row>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import { required, maxLength } from 'vuelidate/lib/validators';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import { url } from '@/helpers';
import Upload from '@/components/common/Upload';
import BaseInput from '@/components/common/BaseInput';
import CustomCheckboxGroup from '@/components/common/CustomCheckboxGroup';
import profilePicPlaceholder from '@/assets/svg/users.svg';

export default {
  name: 'BusinessForm',
  components: {
    BaseInput,
    VueGoogleAutocomplete,
    CustomCheckboxGroup,
    Upload,
  },
  data() {
    return {
      currentLanguage: {
        code: 'en',
        name: 'English',
      },
      locations: [],
      otherService: '',
      avatar: null,
      isSavingData: false,
      email: '',
      locationErrors: false,
      otherServiceErr: false,
      imageSrc: '',
      businessType: '',
      businessInfo: {
        name: '',
        website: '',
        service: '',
        locations: [''],
        customer: '',
        age: [],
      },
      profSources: {
        service: [
          'Legal Services',
          'Real Estate',
          'Financial',
          'Medical',
          'Home',
          'Coaching',
          'Fitness',
          'Hospitality',
          'Other',
        ],
        customer: ['Consumers', 'Businesses', 'Both', 'Not Sure'],
        age: [
          { id: 1, name: 'Gen A (ages 0-6)' },
          { id: 2, name: 'Gen Z (ages 7-24)' },
          { id: 3, name: 'Millennials (ages 25-40)' },
          { id: 4, name: 'Gen X (ages 41-56)' },
          { id: 5, name: 'Baby Boomers (ages 57-76)' },
        ],
      },
      ecommerceSources: {
        service: [
          'Toys, Kids and Baby',
          'Accessories',
          'Food & beverage',
          'Beauty',
          'Home, garden, and tools',
          'Other',
        ],
        customer: ['Consumers', 'Businesses', 'Both', 'Not Sure'],
        age: [
          { id: 1, name: 'Gen A (ages 0-6)' },
          { id: 2, name: 'Gen Z (ages 7-24)' },
          { id: 3, name: 'Millennials (ages 25-40)' },
          { id: 4, name: 'Gen X (ages 41-56)' },
          { id: 5, name: 'Baby Boomers (ages 57-76)' },
        ],
      },
      techSources: {
        service: ['Hardware', 'Software', 'Both'],
        customer: ['Consumers', 'Businesses', 'Both', 'Not Sure'],
        age: [
          { id: 1, name: 'Gen A (ages 0-6)' },
          { id: 2, name: 'Gen Z (ages 7-24)' },
          { id: 3, name: 'Millennials (ages 25-40)' },
          { id: 4, name: 'Gen X (ages 41-56)' },
          { id: 5, name: 'Baby Boomers (ages 57-76)' },
        ],
      },
    };
  },
  validations: {
    businessInfo: {
      name: {
        required,
        maxLength: maxLength(50),
      },
      website: {
        required,
        url,
      },
      service: {
        required,
      },
      locations: {
        required,
      },
      customer: {
        required,
      },
      age: {
        required,
      },
    },
  },
  computed: {
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v.businessInfo[field].$dirty) return errors;
        switch (field) {
          case 'name':
            if (!this.$v.businessInfo.name.required) {
              errors.push('Please provide your Business Name.');
            }
            if (!this.$v.businessInfo.name.maxLength) {
              errors.push('Business name must be less than 50 characters length.');
            }
            break;
          case 'website':
            if (!this.$v.businessInfo.website.required) {
              errors.push('Please provide your Website.');
            }
            if (!this.$v.businessInfo.website.url) {
              errors.push('Please provide valid url.');
            }
            break;
          case 'service':
            if (!this.$v.businessInfo.service.required) {
              errors.push('Please provide Service.');
            }
            break;
          case 'customer':
            if (!this.$v.businessInfo.customer.required) {
              errors.push('Please provide Customer.');
            }
            break;
          case 'age':
            if (!this.$v.businessInfo.age.required) {
              errors.push('Please choose atleast one Age.');
            }
            break;
          default:
            break;
        }
        return errors;
      };
    },
    prof() {
      return [
        {
          name: 'name',
          placeholder: 'Business Name',
        },
        {
          name: 'website',
          placeholder: 'Website',
        },
        {
          name: 'service',
          placeholder: 'What type of services do you offer?',
        },
        {
          name: 'locations',
          placeholder: 'Where do you provide services?',
        },
        {
          name: 'customer',
          placeholder: 'Who is your customer?',
        },
        {
          name: 'age',
          placeholder: 'What age is your customer?',
        },
      ];
    },
    ecommerce() {
      return [
        {
          name: 'name',
          placeholder: 'Business Name',
        },
        {
          name: 'website',
          placeholder: 'Website',
        },
        {
          name: 'service',
          placeholder: 'What type of physical product do you sell?',
        },
        {
          name: 'customer',
          placeholder: 'Who is your customer?',
        },
        {
          name: 'age',
          placeholder: 'What age is your customer?',
        },
      ];
    },
    tech() {
      return [
        {
          name: 'name',
          placeholder: 'Business Name',
        },
        {
          name: 'website',
          placeholder: 'Website',
        },
        {
          name: 'service',
          placeholder: 'What type of technology do you sell?',
        },
        {
          name: 'customer',
          placeholder: 'Who does your technology help?',
        },
        {
          name: 'age',
          placeholder: 'What age is your customer?',
        },
      ];
    },
    info() {
      if (this.businessType === 'E-Commerce') {
        return this.ecommerce;
      }
      if (this.businessType === 'Professional Services') {
        return this.prof;
      }
      if (this.businessType === 'Technology') {
        return this.tech;
      }
      return [];
    },
    sources() {
      if (this.businessType === 'E-Commerce') {
        return this.ecommerceSources;
      }
      if (this.businessType === 'Professional Services') {
        return this.profSources;
      }
      if (this.businessType === 'Technology') {
        return this.techSources;
      }
      return {};
    },
    profilePicture() {
      if (this.avatar === null || this.avatar === 'null') {
        return profilePicPlaceholder;
      }
      if (this.avatar instanceof File) {
        return URL.createObjectURL(this.avatar);
      }
      return this.avatar;
    },
    getIcon() {
      if (this.avatar === null || this.avatar === 'null') {
        // eslint-disable-next-line global-require
        return require('@/assets/svg/theme/plus-circle.svg');
      }
      // eslint-disable-next-line global-require
      return require('@/assets/svg/theme/edit-icon.svg');
    },
  },
  watch: {
    locations(newVal) {
      if (this.businessType === 'Professional Services') {
        this.businessInfo.locations = newVal.map((l) => l.value).filter((l) => l !== '');
      }
    },
  },
  methods: {
    ...mapActions('onboarding', ['getOnboardingUserProfile', 'setOnboardingBusinessProfile']),
    goToBackPage() {
      this.$router.go(-1);
    },
    changeLanguage(language) {
      this.currentLanguage = language;
      this.$hyper.translate(language.code, () => {
        localStorage.setItem('app_language', language.code);
      });
      this.$refs.languageSelector.blur();
    },
    setProfilePicture(file) {
      this.avatar = file;
    },
    canShow(index) {
      if (index === 0) {
        return false;
      }
      return true;
    },
    addLocation() {
      this.locations.push({ key: `location-${Date.now()}`, value: '' });
    },
    removeLocation(key) {
      this.locations = this.locations.filter((loc) => loc.key !== key);
      this.locationErr();
    },
    async setLocation(location, key) {
      const loc = this.locations.find((l) => l.key === key);
      if (loc) {
        loc.value = location.newVal.trim();
      }
      this.locations = [...this.locations];
      this.locationErr();
    },
    locationErr() {
      const locationsArr = _.filter(this.locations, (loc) => loc.value !== '');
      this.locationErrors = locationsArr.length === 0;
    },
    checkOtherService() {
      const { otherService } = this;
      if (otherService) {
        this.otherServiceErr = false;
      } else {
        this.otherServiceErr = true;
      }
    },
    async submitForm(e) {
      e.preventDefault();
      this.$v.$touch();
      if (this.businessType === 'Professional Services') {
        this.locationErr();
      }
      if (this.businessInfo.service === 'Other') {
        this.checkOtherService();
      } else {
        this.otherServiceErr = false;
      }
      if (!this.$v.$invalid && !this.locationErrors && !this.otherServiceErr) {
        const {
          name, website, service, locations, customer, age,
        } = this.businessInfo;
        const {
          email, avatar, businessType, otherService,
        } = this;
        const preferredAges = age;
        this.isSavingData = true;
        const formData = new FormData();
        formData.append('avatar', avatar);
        formData.append('email', email);
        formData.append('businessName', name);
        formData.append('website', website);
        formData.append('targetCustomers', customer);
        formData.append('typeOfService', service === 'Other' ? otherService : service);
        formData.append('businessType', businessType);
        formData.append('onboardingState', 4);
        const preferredLanguage = localStorage.getItem('app_language') || 'en';
        formData.append('preferredLanguage', preferredLanguage);
        _.map(preferredAges, (ages) => {
          formData.append('targetCustomerAges', ages);
        });
        _.map(locations, (location) => {
          formData.append('locations', location.trim());
        });
        const response = await this.setOnboardingBusinessProfile(formData);
        this.isSavingData = false;
        if (response) {
          this.$router.push('/onboarding/setup');
        }
      }
    },
  },
  async mounted() {
    const languageCode = localStorage.getItem('app_language') || 'en';
    const language = this.$hyper.getLanguageInfoFromCode(languageCode);
    this.currentLanguage = language;
    this.changeLanguage(languageCode);
    this.addLocation();
    this.businessType = decodeURIComponent(this.$route.params.businessVertical);
    const onboardingEmail = localStorage.getItem('BS_ONBOARDING_EMAIL');
    const business = await this.getOnboardingUserProfile(onboardingEmail);
    if (business) {
      this.email = business.email;
    }
  },
};
</script>

<style lang="scss" scoped>
.register-page {
  .back-div {
    cursor: pointer;
    display: flex;
    height: fit-content;
    margin-left: 49px;
    .back-icon {
      width: 24px;
      height: 24px;
    }
    .back-text {
      padding-left: 6px;
      font-family: $fontFamily1;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      color: $charcoalBlack;
    }
  }
  .logo-div {
    margin-right: 10%;
  }
}
::v-deep .v-input input::placeholder {
  font-family: $fontFamily1;
  font-weight: bold;
  font-size: 15px !important;
  line-height: 18px;
  color: #2d2d2d;
  opacity: 0.4;
}
.location-input {
  display: flex;
  align-items: center;
  .v-input__control {
    max-width: 93%;
  }
  .close {
    padding-left: 5px;
    font-size: 1.4375rem;
  }
}
.location-input.error--text .v-input__slot {
  border: 1px solid #ff0808 !important;
}
.other-input-field {
  margin-top: -15px;
  padding-left: 2.3rem;
  height: 45px !important;
  ::v-deep .input-field {
      width: 100%;
  }
}
.other-input-field.border-red ::v-deep .v-input__slot {
  border: 1px solid red !important;
}
.other-input-field-error.v-text-field__details {
  padding-left: 2.3rem;
}
.v-input__slot {
    margin-left: 12px !important;
    min-height: 44px !important;
    border: 1px solid #E0E0E0!important;
    ::v-deep.v-text-field__slot {
      padding-left: 5px;
      width: 355px !important;
    }
  }
  .v-input__control {
    max-height: 45px !important;
    max-width: 100%;
  }
::v-deep {
  .v-input__slot {
    margin-bottom: 0px;
  }
}
.contactInfo {
  .v-text-field--outlined {
    margin-bottom: 0px !important;
  }
}
.profile-image-container {
  width: 90px;
  height: 90px;
  position: relative;
  &.upload {
    cursor: pointer;
  }
  .image-background {
    ::v-deep .v-image__image {
      background-size: 50px 50px;
    }
  }
}
.profile-image {
  width: 100%;
  border-radius: 3.125rem;
  object-fit: contain;
  border: 2px solid #E5E5E5;
  background-color: #ffffff;
}
.profile-image-loading {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.profile-image-overlay {
  position: absolute;
  height: 1.875rem;
  width: 1.875rem !important;
  bottom: 3px;
  left: 64px;
  width: 100%;
  border-radius: 0 0 1.375rem 1.375rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #d0d0d0;
  border-radius: 1.875rem;
}
.profile-image-overlay.profilePicture {
  .v-responsive.v-image {
    top: 7px;
    left: 7px;
    ::v-deep .v-image__image {
      height: 0.875rem;
      width: 0.875rem;
    }
  }
}
.border-none {
  border: none;
  .profile-image-overlay {
    border-radius: 0 0 1.25rem 1.25rem;
  }
}
.label-title {
  font-family: $fontFamily1;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: $neutral1;
  margin-bottom: 10px;
}
.addLocation {
  cursor: pointer;
  font-family: $fontFamily1;
  font-weight: bold;
  font-size: 15px;
  text-decoration-line: underline;
  color: $secondary1;
}
.contact-input {
  min-width: 250px;
  width: 100%;
  ::v-deep .v-input__slot {
    border: 1px solid $silver !important;
  }
}
.line:after {
  content: '';
  display: block;
  width: 179px;
  height: 2px;
  background: #d0d0d0;
}
.gray-text {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: #828282;
}
::v-deep .v-input--checkbox .v-label {
  font-family: $fontFamily1;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: $neutral11;
  line-height: 18px;
}
.filter-checkbox {
  ::v-deep {
    .v-icon {
      border-radius: 4px;
      border: 2px solid #dadada !important;
      &.mdi-check {
        border: 2px solid $primary1 !important;
        font-size: 1rem;
      }
    }
  }
}
::v-deep .age-field {
  .input-field-label {
    display: none;
  }
  .input-field {
    margin: 10px 0px;
  }
}
::v-deep {
  .v-input--radio-group__input {
    flex-direction: column !important;
    .v-label {
      font-family: $fontFamily1;
      font-weight: 500;
      font-size: 15px;
      color: $neutral11;
      line-height: 18px;
    }
    .input-field {
      min-height: 1.5rem !important;
      margin: 0px;
      background: none !important;
      box-shadow: none !important;
      border: none !important;
    }
  }
}
.sub-title {
  font-family: $fontFamily1;
  font-size: 16px;
  text-align: center;
  color: $neutral1;
  .capital-text {
    font-family: $fontFamily1;
    font-weight: 500;
    font-size: 15px;
    color: #828282;
    line-height: 18px;
    letter-spacing: 2px;
  }
}
.register-button-grid {
  display: grid;
}
.register-button {
  min-width: auto;
  width: 15rem;
  height: 3rem !important;
  border-radius: 1.5625rem;
  box-shadow: none;
  margin-top: 8px;
  color: $neutral6;
  ::v-deep .v-btn__content {
    font-family: $fontFamily1;
    font-size: 18px;
    font-weight: bold;
  }
}
.register-form-wrapper {
  z-index: 20;
  padding: 0.75rem 0px;
  background: none;
  height: 100%;
}
.center-alignment {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-bar {
  margin-top: 1rem;
  .logo {
    width: 15.625rem;
  }
}
.body-content {
  margin-top: 3.125rem;
}
.register-text {
  margin-top: 10px;
  margin-bottom: 1.5625rem;
  color: $neutral11;
  font-family: $fontFamily1;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: -0.48px;
  text-align: center;
}
.register-divider {
  margin-top: 2rem;
  margin-bottom: 1rem;
  border: 1px solid #d0d0d0;
}
@media (max-width: 960px) {
  .contact-input {
    width: 100%;
  }
  .sub-title {
    font-size: small;
  }
}
@media (min-width: 601px) and (max-width: 960px) {
  .register-form-wrapper {
    height: 100%;
    padding: 0px;
  }
  .back-box {
    // justify-content: flex-end;
    .back-div {
      margin-left: 20px !important;
      .back-text {
        display: none;
      }
    }
  }
}
@media (max-width: 600px) {
  .register-text {
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem;
  }
  .register-form-wrapper {
    height: 100%;
    padding: 0px;
  }
  .back-box {
    // justify-content: flex-end;
    .back-div {
      margin-left: 10px !important;
      .back-text {
        display: none;
      }
    }
  }
}
@media only screen and (min-device-width: 961px) and (max-device-width: 1340px) {
  .register-form-wrapper {
    display: flex;
    flex-direction: column;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }
}
</style>
