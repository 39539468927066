<template>
  <v-container class="contactInfo" fluid fill-height pa-0>
    <v-row no-gutters class="register-page h-100 w-100 align-center flex-column">
      <div class="d-flex w-100 header-bar">
        <v-row class="ma-auto">
          <v-col cols="4"></v-col>
          <v-col cols="4" class="d-flex justify-center align-center pa-0">
            <div class="logo-div">
              <img src="@/assets/svg/logo-colored.svg" class="logo" />
            </div>
          </v-col>
          <v-col cols="4">
            <div class="d-flex justify-end align-center mr-2"
              >
                <v-select
                  class="notranslate"
                  v-model="currentLanguage"
                  :items="$hyper.languages"
                  item-text="name"
                  item-value="code"
                  label="Language"
                  return-object
                  solo
                  @select="changeLanguage"
                  flat
                  style="max-width:200px; height:50px; z-index:99"
                  ref="languageSelector"
                >
                    <template v-slot:item="{ item }">
                      <v-list-item class="notranslate" @click="changeLanguage(item)">
                        {{ item.name }}
                      </v-list-item>
                    </template>
                  </v-select>
            </div>
          </v-col>
        </v-row>
      </div>
      <v-col class="center-alignment" col-xs="12" col-sm="12" col-md="5" col-lg="8" col-xl="8">
        <v-card class="register-form-wrapper text-center" elevation="0">
          <v-row class="flex-column h-100 ma-auto pa-2">
            <div class="body-content">
              <p class="register-text">Welcome to Blended Sense!</p>
              <div class="sub-title">Please enter your contact information.</div>
              <v-row class="flex-column align-center ma-auto">
                <v-row class="ma-auto justify-center">
                  <Upload
                    mode="justImage"
                    @change="setProfilePicture"
                    :acceptedFiles="'.jpg,.jpeg,.png'"
                  >
                    <div class="d-flex ma-auto profile-image-container cursor-pointer">
                      <v-img
                        class="profile-image"
                        :class="{ 'image-background': getIcon.indexOf('plus') >= 0 }"
                        :src="profilePicture"
                        alt="Profile"
                      />
                      <div class="profile-image-overlay" :class="{ profilePicture: avatar }">
                        <v-img :src="getIcon" />
                      </div>
                    </div>
                  </Upload>
                </v-row>
                <div>
                  <v-form class="register-form" @submit="submitForm">
                    <div>
                      <v-row class="flex-column ma-auto">
                        <div class="text-left"><label class="font-label">Name*</label></div>
                        <v-row class="justify-space-between ma-auto">
                          <BaseInput
                            background-color="#ffff"
                            class="mr-2 contact-input"
                            solo
                            dense
                            flat
                            placeholder="First Name"
                            name="firstName"
                            v-model.trim="firstName"
                            :status="errors('firstName').length ? 'error' : 'normal'"
                            :error-messages="errors('firstName')"
                            @input="$v.firstName.$touch()"
                            @blur="$v.firstName.$touch()"
                          />
                          <BaseInput
                            background-color="#ffff"
                            class="contact-input"
                            solo
                            dense
                            flat
                            placeholder="Last Name"
                            name="lastName"
                            v-model.trim="lastName"
                            :status="errors('lastName').length ? 'error' : 'normal'"
                            :error-messages="errors('lastName')"
                            @input="$v.lastName.$touch()"
                            @blur="$v.lastName.$touch()"
                          />
                        </v-row>
                      </v-row>
                      <v-row class="flex-column ma-auto">
                        <BaseInput
                          background-color="#ffff"
                          solo
                          dense
                          flat
                          inputLabel="Email*"
                          name="email"
                          class="contact-input"
                          v-model.trim="email"
                          :status="errors('email').length ? 'error' : 'normal'"
                          :error-messages="errors('email')"
                          @input="$v.email.$touch()"
                          @blur="$v.email.$touch()"
                          :disabled="setDisable"
                        />
                      </v-row>
                      <v-row class="flex-column ma-auto">
                        <v-col md="8" sm="8" xs="12" lg="8" xl="8" class="pa-0">
                          <BaseInput
                            background-color="#ffff"
                            class="contact-input"
                            v-mask="'###-###-####'"
                            type="tel"
                            solo
                            dense
                            flat
                            inputLabel="Phone Number*"
                            name="phoneNumber"
                            v-model.trim="phoneNumber"
                            :status="errors('phoneNumber').length ? 'error' : 'normal'"
                            :error-messages="errors('phoneNumber')"
                            @input="$v.phoneNumber.$touch()"
                            @blur="$v.phoneNumber.$touch()"
                          >
                          </BaseInput>
                        </v-col>
                      </v-row>
                    </div>
                    <v-divider class="register-divider" />
                    <div class="justify-center register-button-grid">
                      <v-btn
                        class="register-button btn-purple"
                        type="submit"
                        block
                        :disabled="($v.$dirty && $v.$invalid) || isSavingData"
                        :loading="isSavingData"
                        >Continue</v-btn
                      >
                    </div>
                  </v-form>
                </div>
              </v-row>
            </div>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import {
  required, email, maxLength, minLength,
} from 'vuelidate/lib/validators';
import { mask } from 'vue-the-mask';
import BaseInput from '@/components/common/BaseInput';
import profilePicPlaceholder from '@/assets/svg/users.svg';
import Upload from '@/components/common/Upload';

export default {
  name: 'Contact',
  components: {
    BaseInput,
    Upload,
  },
  directives: {
    mask,
  },
  validations: {
    firstName: {
      required,
      maxLength: maxLength(50),
    },
    lastName: {
      required,
      maxLength: maxLength(50),
    },
    email: {
      required,
      email,
    },
    phoneNumber: {
      required,
      minLength: minLength(12),
    },
  },
  data() {
    return {
      avatar: null,
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: null,
      isSavingData: false,
      referenceEmail: '',
      currentLanguage: {
        code: 'en',
        name: 'English',
      },
    };
  },
  computed: {
    setDisable() {
      const loginType = localStorage.getItem('BS_SIGN_IN_MODE');
      if (loginType === 'SSO') {
        return true;
      }
      return false;
    },
    profilePicture() {
      if (this.avatar === null || this.avatar === 'null') {
        return profilePicPlaceholder;
      }
      if (this.avatar instanceof File) {
        return URL.createObjectURL(this.avatar);
      }
      return this.avatar;
    },
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v[field].$dirty) return errors;
        switch (field) {
          case 'firstName':
            if (!this.$v.firstName.required) {
              errors.push('Please provide your first name.');
            }
            if (!this.$v.firstName.maxLength) {
              errors.push('First name must be less than 50 characters length.');
            }
            break;
          case 'lastName':
            if (!this.$v.lastName.required) {
              errors.push('Please provide your last name.');
            }
            if (!this.$v.lastName.maxLength) {
              errors.push('Last name must be less than 50 characters length.');
            }
            break;
          case 'email':
            if (!this.$v.email.email) {
              errors.push('Please provide a valid email address.');
            }
            if (!this.$v.email.required) {
              errors.push('Please provide your email address.');
            }
            break;
          case 'phoneNumber':
            if (!this.$v.phoneNumber.required) {
              errors.push('Please provide phone number.');
            }
            if (!this.$v.phoneNumber.minLength) {
              errors.push('Please provide valid phone number.');
            }
            break;
          default:
            break;
        }
        return errors;
      };
    },
    getIcon() {
      if (this.avatar) {
        // eslint-disable-next-line global-require
        return require('@/assets/svg/theme/edit-icon.svg');
      }
      // eslint-disable-next-line global-require
      return require('@/assets/svg/theme/plus-circle.svg');
    },
  },
  methods: {
    ...mapActions('onboarding', ['getOnboardingUserProfile', 'setOnboardingUserProfile']),
    setProfilePicture(file) {
      this.avatar = file;
    },
    changeLanguage(language) {
      this.currentLanguage = language;
      this.$hyper.translate(language.code, () => {
        localStorage.setItem('app_language', language.code);
      });
      this.$refs.languageSelector.blur();
    },
    async submitForm(e) {
      e.preventDefault();
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isSavingData = true;
        const formData = new FormData();
        formData.append('email', this.email);
        formData.append('referenceEmail', this.referenceEmail);
        formData.append('firstName', this.firstName);
        formData.append('lastName', this.lastName);
        formData.append('phoneNumber', this.phoneNumber);
        formData.append('avatar', this.avatar);
        formData.append('onboardingState', 2);
        const preferredLanguage = localStorage.getItem('app_language') || 'en';
        formData.append('preferredLanguage', preferredLanguage);
        const response = await this.setOnboardingUserProfile(formData);
        if (!response) {
          this.isSavingData = false;
          return;
        }
        const encodedData = localStorage.getItem('BS_ONBOARDING_INFO');
        if (encodedData) {
          const loginInfo = JSON.parse(atob(encodedData));
          loginInfo.email = this.email;
          localStorage.setItem('BS_ONBOARDING_INFO', btoa(JSON.stringify(loginInfo)));
        }
        localStorage.setItem('BS_ONBOARDING_EMAIL', this.email);
        if (response.token) {
          localStorage.setItem('__bs_valid', JSON.stringify({ token: response.token }));
        }
        this.isSavingData = false;
        if (response) {
          this.$router.push('/onboarding/customer');
        }
      }
    },
  },
  async mounted() {
    const languageCode = localStorage.getItem('app_language') || 'en';
    const language = this.$hyper.getLanguageInfoFromCode(languageCode);
    this.currentLanguage = language;
    this.changeLanguage(languageCode);
    const onboardingEmail = localStorage.getItem('BS_ONBOARDING_EMAIL');
    const userInfo = await this.getOnboardingUserProfile(onboardingEmail);
    if (userInfo) {
      this.referenceEmail = onboardingEmail;
      this.firstName = userInfo.firstName;
      this.lastName = userInfo.lastName;
      this.email = userInfo.email;
      this.avatar = userInfo.profilePic;
      this.phoneNumber = userInfo.number;
    }
  },
};
</script>

<style lang="scss" scoped>
.register-page {
  .back-div {
    cursor: pointer;
    display: flex;
    height: fit-content;
    margin-left: 49px;
    .back-icon {
      width: 24px;
      height: 24px;
    }
    .back-text {
      padding-left: 6px;
      font-family: $fontFamily1;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      color: $charcoalBlack;
    }
  }
}
::v-deep .v-input input::placeholder {
  font-style: normal;
  color: #2D2D2D;
  font-family: $fontFamily1;
  font-weight: bold;
  font-size: 14px !important;
  line-height: 17px !important;
  opacity: 0.4;
}
.register-form-wrapper::v-deep .v-input__slot {
  border: 1px solid $silver !important;
}
::v-deep .v-input input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px #ffff inset !important;
}
.profile-image-container {
  width: 90px;
  height: 90px;
  position: relative;
  &.upload {
    cursor: pointer;
  }
  .image-background {
    ::v-deep .v-image__image {
      background-size: 50px 50px;
    }
  }
}
.profile-image {
  width: 100%;
  border-radius: 3.125rem;
  object-fit: contain;
  border: 2px solid #E5E5E5;
  background-color: #ffffff;
}
.profile-image-loading {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.profile-image-overlay {
  position: absolute;
  height: 1.875rem;
  width: 1.875rem !important;
  bottom: 3px;
  left: 64px;
  width: 100%;
  border-radius: 0 0 1.375rem 1.375rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #d0d0d0;
  border-radius: 1.875rem;
}
.profile-image-overlay.profilePicture {
  .v-responsive.v-image {
    top: 7px;
    left: 7px;
    ::v-deep .v-image__image {
      height: 0.875rem;
      width: 0.875rem;
    }
  }
}
.border-none {
  border: none;
  .profile-image-overlay {
    border-radius: 0 0 1.25rem 1.25rem;
  }
}
.register-form-wrapper {
  z-index: 20;
  padding: 0.75rem 0px;
  background: none;
  height: 100%;
}
.center-alignment {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-bar {
  margin-top: 1rem;
  .logo {
    width: 15.625rem;
  }
}
.body-content {
  margin-top: 3.125rem;
}
.register-text {
  margin-top: 10px;
  margin-bottom: 1.5625rem;
  color: $neutral11;
  font-family: $fontFamily1;
  font-size: 30px;
  line-height: 36px;
  font-weight: 900;
  text-align: center;
}
.sub-title {
  font-family: $fontFamily1;
  font-size: 24px;
  text-align: center;
  color: $charcoalBlack;
  padding-bottom: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 29px;
}
.register-divider {
  margin-top: 2rem;
  margin-bottom: 1rem;
  border: 1px solid #d0d0d0;
}
.register-form {
  margin: auto;
  z-index: 2;
  font-family: $fontFamily1;
}
.register-button-grid {
  display: grid;
}
.register-button {
  min-width: auto;
  width: 12.5rem;
  height: 3rem !important;
  border-radius: 1.5625rem;
  box-shadow: none;
  margin-top: 8px;
  color: $neutral6;
  ::v-deep .v-btn__content {
    font-family: $fontFamily1;
    font-size: 18px;
    font-weight: bold;
  }
}
::v-deep .v-card:not(.v-card--flat):not(.v-picker--time):not(.v-picker--date) {
  border-radius: 0px !important;
}
::v-deep .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
  // background: $neutral3;
  border: 0px;
}
.v-btn.v-size--default {
  height: 3rem !important;
}
.vregister-form .v-btn__content {
  font-size: 1rem;
}
::v-deep .theme--light.v-label {
  color: $neutral11;
  font-size: 0.9375rem;
  font-weight: 600;
  padding-left: 8px;
}
::v-deep .v-icon.v-icon.v-icon--link {
  color: rgba(45, 45, 45, 0.2);
}
::v-deep .v-input--selection-controls__input {
  margin-right: 0px !important;
}
::v-deep .v-input {
  input:-webkit-autofill {
    border-radius: 5px;
    -webkit-box-shadow: 0 0 0 50px $neutral3 inset;
  }
  .v-messages {
    font-size: 0.875rem !important;
    letter-spacing: -0.4px;
  }
}
::v-deep .v-input input {
  font-size: 14px;
  line-height: 17px !important;
}
::v-deep .v-text-field__slot {
  font-size: 0.75rem;
}
@media (max-width: 598px) {
  .register-text {
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem;
  }
  .register-button {
    margin-top: 10px;
  }
  .register-form-wrapper {
    height: 100%;
    padding: 0px;
  }
  .back-box {
    justify-content: flex-end;
    .back-div {
      margin-left: 10px !important;
      .back-text {
        display: none;
      }
    }
  }
}
@media (min-width: 601px) and (max-width: 960px) {
  .register-form {
    margin: auto;
  }
  .register-form-wrapper {
    height: 100%;
    padding: 0px;
  }
  .back-box {
    justify-content: flex-end;
    .back-div {
      margin-left: 20px !important;
      .back-text {
        display: none;
      }
    }
  }
}
@media only screen and (min-device-width: 961px) and (max-device-width: 132.5rem) {
  .register-form {
    margin-top: 10px;
  }
  .register-form-wrapper {
    display: flex;
    flex-direction: column;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }
}
@media (min-width: 1367px) {
  .divider {
    max-width: 100%;
    margin: auto;
    margin-bottom: 115px;
    border: solid 2px rgba(223, 223, 223, 0.15);
    background: rgba(45, 45, 45, 0.15);
  }
}
@media (max-width: 1330px) {
  .divider {
    display: none;
  }
}
</style>
