<template>
  <v-container class="contactInfo" fluid fill-height pa-0>
    <v-row no-gutters class="register-page h-100 w-100 justify-center align-center flex-column">
      <div class="d-flex w-100 header-bar">
        <v-row>
          <v-col cols="4" class="pa-0 d-flex align-center back-box">
            <div @click="goToBackPage" class="back-div">
              <v-icon color="black" class="back-icon">mdi-arrow-left</v-icon>
              <span class="back-text">Back</span>
            </div>
          </v-col>
          <v-col cols="4" class="d-flex justify-center align-center pa-0">
            <div class="logo-div">
              <img src="@/assets/svg/logo-colored.svg" class="logo" />
            </div>
          </v-col>
          <v-col cols="4">
            <div class="d-flex justify-end align-center mr-4">
                <v-select
                  class="notranslate"
                  v-model="currentLanguage"
                  :items="$hyper.languages"
                  item-text="name"
                  item-value="code"
                  label="Language"
                  return-object
                  solo
                  @select="changeLanguage"
                  flat
                  style="max-width:200px; height:50px; z-index: 99"
                  ref="languageSelector"
                >
                    <template v-slot:item="{ item }">
                      <v-list-item class="notranslate" @click="changeLanguage(item)">
                        {{ item.name }}
                      </v-list-item>
                    </template>
                  </v-select>
            </div>
          </v-col>
        </v-row>
      </div>
      <v-col class="center-alignment" col-xs="12" col-sm="12" col-md="5" col-lg="8" col-xl="8">
        <v-card class="register-form-wrapper text-center" elevation="0">
          <v-row class="flex-column h-100 ma-auto pa-2">
            <div class="body-content">
              <p class="register-text">I need content for . . .</p>
              <v-row class="flex-column align-center ma-auto">
                <v-radio-group row hide-details v-model="verticle" class="businessGroup">
                  <div
                    v-for="(option, index) in businessVerticals"
                    :key="index"
                    class="input-field"
                  >
                    <v-radio :label="option.title" :value="option.name" color="info"> </v-radio>
                    <div class="desc">{{ option.description }}</div>
                  </div>
                </v-radio-group>
              </v-row>
              <v-divider class="register-divider" />
              <div class="justify-center register-button-grid">
                <v-btn
                  class="register-button btn-purple"
                  type="submit"
                  block
                  :disabled="isVerticle"
                  @click="$router.push(`/onboarding/business/${verticle}`)"
                  >Continue</v-btn
                >
              </div>
            </div>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'BusinessType',
  data() {
    return {
      currentLanguage: {
        code: 'en',
        name: 'English',
      },
      businessVerticals: [
        {
          name: 'E-Commerce',
          description: 'I have physical products or a retail store to sell.',
          title: 'My e-commerce business',
        },
        {
          name: 'Professional Services',
          // eslint-disable-next-line quotes
          description: `I am in a service based business. I'm a realtor, doctor, dentist, etc.`,
          title: 'My professional services business',
        },
        {
          name: 'Technology',
          // eslint-disable-next-line quotes
          description: `I'm in a tech start up.`,
          title: 'My technology startup',
        },
      ],
      verticle: '',
    };
  },
  watch: {
    verticle(newVal) {
      if (newVal) {
        localStorage.setItem('BS_ONBOARDING_BUSINESS_TYPE', newVal);
      }
    },
  },
  computed: {
    isVerticle() {
      if (!_.isEmpty(this.verticle)) {
        return false;
      }
      return true;
    },
  },
  methods: {
    goToBackPage() {
      this.$router.go(-1);
    },
    changeLanguage(language) {
      this.currentLanguage = language;
      this.$hyper.translate(language.code, () => {
        localStorage.setItem('app_language', language.code);
      });
      this.$refs.languageSelector.blur();
    },
  },
  mounted() {
    const languageCode = localStorage.getItem('app_language') || 'en';
    const language = this.$hyper.getLanguageInfoFromCode(languageCode);
    this.currentLanguage = language;
    this.changeLanguage(languageCode);
    const verticle = localStorage.getItem('BS_ONBOARDING_BUSINESS_TYPE');
    if (verticle) {
      this.verticle = verticle;
    }
  },
};
</script>

<style lang="scss" scoped>
.register-page {
  .back-div {
    cursor: pointer;
    display: flex;
    height: fit-content;
    margin-left: 49px;
    .back-icon {
      width: 24px;
      height: 24px;
    }
    .back-text {
      padding-left: 6px;
      font-family: $fontFamily1;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      color: $charcoalBlack;
    }
  }
  .logo-div {
    margin-right: 10%;
  }
}
::v-deep .v-input--radio-group__input {
  flex-direction: column !important;
  .v-input--selection-controls__input {
    margin-right: 0.5rem;
  }
  .v-label {
    font-family: $fontFamily1;
    font-style: normal;
    font-weight: 900;
    line-height: 19px;
    font-size: 16px;
    color: $charcoalBlack;
  }
  .input-field {
    padding: 15px;
    margin-bottom: 15px;
    box-shadow: none !important;
    max-width: 408px;
  }
  .desc {
    padding-left: 2.3rem;
    font-family: $fontFamily1;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #8c8c8c;
  }
}
.register-button-grid {
  display: grid;
}
.register-button {
  min-width: auto;
  width: 12.5rem;
  height: 3rem !important;
  border-radius: 1.5625rem;
  box-shadow: none;
  margin-top: 8px;
  color: $neutral6;
  ::v-deep .v-btn__content {
    font-family: $fontFamily1;
    font-size: 18px;
    font-weight: bold;
    line-height: 22px;
  }
}
.register-form-wrapper {
  z-index: 20;
  padding: 0.75rem 0px;
  background: none;
  height: 100%;
}
.center-alignment {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-bar {
  margin-top: 1rem;
  .logo {
    width: 15.625rem;
  }
}
.body-content {
  margin-top: 3.125rem;
}
.register-text {
  margin-top: 10px;
  margin-bottom: 45px;
  font-family: $fontFamily1;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  font-style: normal;
  line-height: 38px;
  color: $neutral1;
}
.register-divider {
  margin-top: 2rem;
  margin-bottom: 1rem;
  border: 1px solid #d0d0d0;
}
@media (min-width: 601px) and (max-width: 960px) {
  .register-form-wrapper {
    height: 100%;
    padding: 0px;
  }
  .back-box {
    // justify-content: flex-end;
    .back-div {
      margin-left: 20px !important;
      .back-text {
        display: none;
      }
    }
  }
}
@media (max-width: 600px) {
  .register-text {
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem;
  }
  .register-form-wrapper {
    height: 100%;
    padding: 0px;
  }
  .input-field {
    max-width: none !important;
  }
  .back-box {
    // justify-content: flex-end;
    .back-div {
      margin-left: 10px !important;
      .back-text {
        display: none;
      }
    }
  }
}
@media only screen and (min-device-width: 961px) and (max-device-width: 1340px) {
  .register-form-wrapper {
    display: flex;
    flex-direction: column;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }
}
</style>
