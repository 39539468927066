import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"contactInfo",attrs:{"fluid":"","fill-height":"","pa-0":""}},[_c(VRow,{staticClass:"register-page h-100 w-100 justify-center align-center flex-column",attrs:{"no-gutters":""}},[_c('div',{staticClass:"d-flex w-100 header-bar"},[_c(VRow,[_c(VCol,{staticClass:"pa-0 d-flex align-center back-box",attrs:{"cols":"4"}},[_c('div',{staticClass:"back-div",on:{"click":_vm.goToBackPage}},[_c(VIcon,{staticClass:"back-icon",attrs:{"color":"black"}},[_vm._v("mdi-arrow-left")]),_c('span',{staticClass:"back-text"},[_vm._v("Back")])],1)]),_c(VCol,{staticClass:"d-flex justify-center align-center pa-0",attrs:{"cols":"4"}},[_c('div',{staticClass:"logo-div"},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/svg/logo-colored.svg")}})])]),_c(VCol,{attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex justify-end align-center mr-4"},[_c(VSelect,{ref:"languageSelector",staticClass:"notranslate",staticStyle:{"max-width":"200px","height":"50px","z-index":"99"},attrs:{"items":_vm.$hyper.languages,"item-text":"name","item-value":"code","label":"Language","return-object":"","solo":"","flat":""},on:{"select":_vm.changeLanguage},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c(VListItem,{staticClass:"notranslate",on:{"click":function($event){return _vm.changeLanguage(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}}]),model:{value:(_vm.currentLanguage),callback:function ($$v) {_vm.currentLanguage=$$v},expression:"currentLanguage"}})],1)])],1)],1),_c(VCol,{staticClass:"center-alignment",attrs:{"col-xs":"12","col-sm":"12","col-md":"5","col-lg":"8","col-xl":"8"}},[_c(VCard,{staticClass:"register-form-wrapper text-center",attrs:{"elevation":"0"}},[_c(VRow,{staticClass:"flex-column h-100 ma-auto pa-2"},[_c('div',{staticClass:"body-content"},[_c('p',{staticClass:"register-text"},[_vm._v("I need content for . . .")]),_c(VRow,{staticClass:"flex-column align-center ma-auto"},[_c(VRadioGroup,{staticClass:"businessGroup",attrs:{"row":"","hide-details":""},model:{value:(_vm.verticle),callback:function ($$v) {_vm.verticle=$$v},expression:"verticle"}},_vm._l((_vm.businessVerticals),function(option,index){return _c('div',{key:index,staticClass:"input-field"},[_c(VRadio,{attrs:{"label":option.title,"value":option.name,"color":"info"}}),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(option.description))])],1)}),0)],1),_c(VDivider,{staticClass:"register-divider"}),_c('div',{staticClass:"justify-center register-button-grid"},[_c(VBtn,{staticClass:"register-button btn-purple",attrs:{"type":"submit","block":"","disabled":_vm.isVerticle},on:{"click":function($event){return _vm.$router.push(("/onboarding/business/" + _vm.verticle))}}},[_vm._v("Continue")])],1)],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }