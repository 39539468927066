<template>
  <v-container fluid fill-height pa-0
    style="background-color: #FFF;"
    :key="onboardingRole"
  >
   <v-row class="mainheader bs-navbar mx-0 pa-2 px-8 space-between align-center">
        <div class="bs-navbar-logo">
          <img src="@/assets/brandings/blendedsense_x.png" class="nav-bs-logo" />
        </div>
      <v-spacer></v-spacer>
      <div v-if="onboardingRole == 'client'" class="d-flex align-center">
        <div>Looking for gigs?</div>&nbsp;
        <div class="ml-1 form-switch-link"
          @click="() => toggleOnboardingRole()"
        >Join as a Creative</div>
      </div>
      <div v-else class="d-flex align-center">
        <div>Want assets?</div>
        <div class="ml-1 form-switch-link"
          @click="() => toggleOnboardingRole()"
        >Join as a Client</div>
      </div>
   </v-row>
   <div class="header">
    <OnboardingNavBar :showProfile=false />
   </div>
    <v-row no-gutters class="register-page">
      <v-col cols="12" md="4" class="center-alignment">
        <v-card class="register-form-wrapper text-center" elevation="0">
          <div class="onboard-title">
            <div class="text">{{onboardTitleText}}</div>
          </div>
        <v-form class="register-form" @submit="submitForm">
          <div class="login-options">
            <div class="login-option-wrapper">
              <v-btn
                style="width:100%"
                elevation="0"
                class="login-option"
                :href="googleAuthLink"
              >
                <img src="@/assets/svg/google.svg" class="login-option-icon mb-1"/>
                Continue with Google
              </v-btn>
            </div>
          </div>
          <v-row align="center" class="px-2 my-4">
            <v-divider></v-divider>
            <div class="mx-2">or</div>
            <v-divider></v-divider>
          </v-row>
          <v-row class="name-row">
            <v-col
              xs="6" sm="6" md="6" lg="6"
              class="d-flex firstname pt-0 pe-0"
            >
              <BaseInput
                solo
                flat
                placeholder="First Name"
                name="First Name"
                v-model.trim="firstName"
                :status="errors('firstName').length ? 'error': 'normal'"
                :error-messages="errors('firstName')"
                @input="$v.firstName.$touch()"
                @blur="$v.firstName.$touch()"
              />
            </v-col>
            <v-col
              xs="6" sm="6" md="6" lg="6"
              class="lastname d-flex justify-center align-center mx-0 pt-0 ps-4 pe-0">
              <BaseInput
                solo
                flat
                placeholder="Last Name"
                name="Last Name"
                class="w-100"
                v-model.trim="lastName"
                :status="errors('lastName').length ? 'error': 'normal'"
                :error-messages="errors('lastName')"
                @input="$v.lastName.$touch()"
                @blur="$v.lastName.$touch()"
              />
            </v-col>
          </v-row>
          <BaseInput
            solo
            flat
            placeholder="Email Address"
            name="email"
            class="mb-2 pb-0 mt-1"
            v-model.trim="email"
            :status="errors('email').length ? 'error': 'normal'"
            :error-messages="errors('email')"
            @input="$v.email.$touch()"
            @blur="$v.email.$touch()"
          />
          <BaseInput
            solo
            flat
            placeholder="Phone Number"
            name="phoneNumber"
            type="tel"
            v-mask="'###-###-####'"
            class="pt-2 mb-2"
            v-model.trim="phoneNumber"
            :status="errors('phoneNumber').length ? 'error': 'normal'"
            :error-messages="errors('phoneNumber')"
            @input="$v.phoneNumber.$touch()"
            @blur="$v.phoneNumber.$touch()"
          />
          <BaseInput
            solo
            flat
            placeholder="Password"
            name="password"
            class="pt-3 mb-3"
            v-model.trim="password"
            :status="errors('password').length ? 'error': 'normal'"
            :error-messages="errors('password')"
            :type="showPassword?'text': 'password'"
            @input="$v.password.$touch()"
            @blur="$v.password.$touch()"
            :appendIconCallback="() => showPassword = !showPassword"
          />
          <div class="terms mt-8">
          <div class="mb-2">By choosing to continue you agree to our</div>
            <a href="https://www.blendedsense.com/privacy-policy" target="_blank">Privacy Policy</a>
            and
            <a href="https://blendedsense-website.webflow.io/terms-of-service" target="_blank">Terms of Service</a>
          </div>
          <div class="btn-container">
            <div class="btn-div">
              <v-btn
                class="register-button btn-purple"
                type="submit"
                block
                :disabled="$v.$dirty && $v.$invalid"
              >Create my Account</v-btn>
            </div>
          </div>
          <div class="already-mrg">
            <p class="acct">
              Already have an account?
              <router-link :to="{ name: 'Login'}" class="login">Login</router-link>
            </p>
          </div>
           <v-row class="asset mx-0 space-between align-center">
      <div v-if="onboardingRole == 'client'" class=" align-center">
        <div>Looking for gigs?</div>&nbsp;
        <div class="ml-1 form-switch-link"
          @click="() => toggleOnboardingRole()"
        >Join as a Creative</div>
      </div>
      <div v-else class="align-center">
        <div>Want assets?</div>
        <div class="ml-1 form-switch-link"
          @click="() => toggleOnboardingRole()"
        >Join as a Client</div>
      </div>
   </v-row>
        </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import {
  required, email, minLength, maxLength,
} from 'vuelidate/lib/validators';
import { mask } from 'vue-the-mask';
import BaseInput from '@/components/common/BaseInput';
import { getNextOnboardingStepInfo } from '@/helpers/onboarding';
import OnboardingNavBar from '@/components/onBoard/creative/OnboardingNavBar';

const namespace = 'user';
export default {
  name: 'Register',
  components: { BaseInput, OnboardingNavBar },
  directives: { mask },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      role: 'creative',
      phoneNumber: '',
      showPassword: false,
      onboardingRole: 'creative',
    };
  },
  computed: {
    googleAuthLink() {
      const baseUrl = process.env.VUE_APP_BASE_API_URL || 'http://localhost:3000';
      return `${baseUrl}/auth/google`;
    },
    errors() {
      return (field) => {
        const errors = [];
        if (!this.$v[field].$dirty) return errors;
        switch (field) {
          case 'firstName':
            if (!this.$v.firstName.required) { errors.push('Please provide your first name.'); }
            if (!this.$v.firstName.maxLength) { errors.push('First name must be less than 50 characters length.'); }
            break;
          case 'lastName':
            if (!this.$v.lastName.required) { errors.push('Please provide your last name.'); }
            if (!this.$v.lastName.maxLength) { errors.push('Last name must be less than 50 characters length.'); }
            break;
          case 'email':
            if (!this.$v.email.email) { errors.push('Please provide a valid email address.'); }
            if (!this.$v.email.required) { errors.push('Please provide your email address.'); }
            break;
          case 'phoneNumber':
            if (!this.$v.phoneNumber.required) {
              errors.push('Please provide phone number.');
            }
            if (!this.$v.phoneNumber.minLength) {
              errors.push('Please provide valid phone number.');
            }
            break;
          case 'password':
            if (!this.$v.password.required) { errors.push('Please provide a password'); }
            break;
          default:
            break;
        }
        return errors;
      };
    },
    onboardTitleText() {
      switch (this.onboardingRole) {
        case 'creative':
          return 'Join the creative network';
        case 'client':
          return 'Sign up as a customer';
        default:
          return 'Create your account';
      }
    },
  },
  methods: {
    ...mapActions(namespace, ['registerUser']),
    async toggleOnboardingRole() {
      const role = this.onboardingRole === 'creative' ? 'client' : 'creative';
      this.onboardingRole = role;
      await localStorage.setItem('role', this.onboardingRole);
      this.$router.push({
        path: this.$route.path,
        query: {
          onboarding_role: role,
        },
      });
    },
    async submitForm(e) {
      e.preventDefault();
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const {
          firstName, lastName, password,
        } = this;
        const payload = {
          firstName,
          lastName,
          email: this.email,
          phoneNumber: this.phoneNumber,
          role: this.onboardingRole,
          password,
        };
        const response = await this.registerUser(payload);
        if (response.success) {
          localStorage.setItem('BS_ONBOARDING_EMAIL', this.email);
          localStorage.setItem('BS_ONBOARDING_INFO', btoa(JSON.stringify(payload)));
          const nextStep = getNextOnboardingStepInfo({
            onboardingState: response.nextState,
            role: this.onboardingRole,
          });
          if (nextStep.url) {
            this.$router.push({
              path: nextStep.url,
            });
          }
        }
      }
    },
  },
  validations: {
    firstName: {
      required,
      maxLength: maxLength(50),
    },
    lastName: {
      required,
      maxLength: maxLength(50),
    },
    email: {
      required,
      email,
    },
    phoneNumber: {
      required,
      minLength: minLength(12),
    },
    password: {
      required,
    },
  },
  mounted() {
    localStorage.removeItem('BS_ONBOARDING_BUSINESS_TYPE');
    localStorage.setItem('loginType', 'register');
    const onboardingRole = this.$route.query.onboarding_role;
    this.onboardingRole = onboardingRole;
    if (['creative', 'client'].includes(onboardingRole)) {
      this.onboardingRole = onboardingRole;
    } else {
      this.onboardingRole = 'creative';
    }
    // set url params if not exists,
    this.$router.replace({
      path: this.$route.path,
      query: {
        onboarding_role: this.onboardingRole,
      },
    });
  },
};
</script>
<style lang="scss" scoped>
 .header {
     display: block;
   }
   .navbar {
     display: none;
   }
.input::placeholder {
  color: green;
}
.form-switch-link {
  color: $secondary1 !important;
  cursor: pointer;
}
.asset {
  justify-content: 'center';
  display: none;
}
.bs-navbar {
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  padding: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}
.header {
  display: none;
}
.login-options{
  padding: 1rem 0rem;
  .login-option{
    display: flex;
    border-radius: 40px;
    border: 1px solid #AEB8BF;
    background: white !important;
    ::v-deep .v-btn__content {
      font-family: $fontFamily1;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #686868;
    }
  }
  .login-option-icon{
    height: 1.125rem;
    margin-right: 1rem;
  }
}
.register-page {
  padding-left: 45px;
  padding-right: 60px;
  display: flex;
  justify-content: center;
}
.register-form-wrapper {
  z-index: 20;
  background: #fff;
  padding: 2rem;
  min-width: 600px !important;
  margin-top: 150px;
}
.center-alignment {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 60px;
}
.already-mrg {
  margin-top: 25px;
}
.alignment {
  display: flex;
  align-items: flex-end;
  justify-content: center
}
.register-text {
  margin-top: 10px;
  margin-bottom: 10px;
  color: $neutral11;
  font-family: $fontFamily1;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
}
.register-form {
  margin: auto;
  z-index: 2;
  font-family: $fontFamily1;
  background: $neutral6;
  max-width: 400px;
  .terms {
    font-family: $fontFamily1;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #262626;
    margin-top: 17px;
    margin-bottom: 5px;
    a {
      text-decoration-line: underline;
      color: $secondary1 !important;
    }
  }
    input:-internal-autofill-selected {
    background-color: red;
  }
  ::v-deep .v-input input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 50px #ffff inset !important;
    background-color: #fff;
  }
    ::v-deep .disabledState.v-text-field--solo .v-input__control > .v-input__slot {
    border: solid 1px #D6D6D6 !important;
  }
  .errorState.v-text-field--solo .v-input__control > .v-input__slot {
    background-color: #fff !important;
  }
}
.firstname{
  width:50%;
}
.lastname{
  width:50%;
}
.btn-container {
  justify-content: center;
  .btn-div {
    // width: 200px;
    .register-button {
      border-radius: 40px;
      box-shadow: none;
      background-color: $secondary1 !important;
      margin-top: 25px;
      color: $neutral6;
      ::v-deep .v-btn__content {
        font-family: $fontFamily1;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
      }
    }
  }
}
::v-deep .v-card:not(.v-card--flat):not(.v-picker--time):not(.v-picker--date) {
  border-radius: 5px !important;
}
.name-row{
  flex-direction: column;
  min-width: 100%;
  height: 80px;
  margin-right:0px;
}
.v-btn.v-size--default {
  height: 40px !important;
}
.login {
  color: $secondary1 !important;
  font-family: $fontFamily1;
  font-size: 15px ;
  font-weight: 600;
}
.acct {
  font-family: $fontFamily1;
  font-size: 15px;
  font-weight: 600;
  color: $neutral11;
}
::v-deep .theme--light.v-label {
  color: $neutral11;
  font-size: 15px;
  font-weight: 600;
  padding-left: 8px;
}
::v-deep .v-icon.v-icon.v-icon--link {
    color: rgba(45, 45, 45, 0.2);
}
::v-deep .v-input--selection-controls__input {
    margin-right: 0px !important;
}
::v-deep .v-input--radio-group.v-input--radio-group--row .v-radio {
  margin-right: 0px;
}
::v-deep .v-input {
 input:-webkit-autofill {
   border-radius: 5px;
  -webkit-box-shadow:0 0 0 50px $neutral3 inset;
 }
 .v-messages {
    font-size: 14px !important;
    letter-spacing:-0.4px;
  }
}
::v-deep .v-input input {
  font-family: $fontFamily1;
  font-size: 15px !important;
  line-height: 18px;
}
::v-deep .v-text-field__slot{
  font-size:12px;
}
::v-deep .v-input input::placeholder {
  font-family: $fontFamily1;
  font-weight: bold;
  font-size: 15px !important;
  line-height: 18px;
  color: #2D2D2D;
  opacity: 0.4;
}
@media (min-width: 601px) {
   .register-form-wrapper {
    border: 1px solid #DEDEDE;
box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08) !important;
  }
}
@media (max-width: 1330px) {
  .divider {
    display: none;
  }
}
@media (min-width: 1367px) {
  .divider{
    max-width: 100%;
    margin: auto;
    margin-bottom: 0px;
    border: solid 2px rgba(223, 223, 223, 0.15);
    background: rgba(45, 45, 45, 0.15);
  }
}
@media only screen and (max-width: 960px) {
  .logo {
    margin-top: 120px !important;
    width: 40%;
  }
  .register-page {
    padding-left: 0px !important;
    padding-right: 0px !important;
    background: $neutral6;
    border: 1px solid #DEDEDE;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08) !important;
  }
  .register-form-wrapper {
    // margin-top: 150px;
  }
  .alignment {
   display: none;
  }
}
@media only screen and (min-device-width: 0px) and (max-device-width: 1000px) {
   .header {
     display: block;
   }
   .asset {
     display: block;
     padding-top: 2rem;
   }
   .mainheader {
     display: none;
   }
  .register-form-wrapper {
    padding-left: 15px;
    padding-right: 15px;
    min-width: unset !important;
    width: 90%;
    margin-top: 50px;
  }
  .name-row{
    flex-direction: column;
    min-width:100%;
    height: 80px;
    margin-bottom: 1rem;
  }
  .already-mrg {
    display: none;
  }
  .onboard-title {
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem;
    font-size: 30px;
    font-weight: 800;
    line-height: 38px;
  }
}
@media only screen and (min-width: 1100px) and (max-width: 1340px) {
  .alignment {
    display: flex;
    align-items: center;
  }
  .register-form {
    width: 100%;
    margin-top: 10px;
  }
  .register-page {
    padding-left: 45px;
    padding-right: 65px;
  }
  .register-form-wrapper {
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    padding-right: 15px;
    border: 1px solid #DEDEDE;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08) !important;
  }
}
.onboard-title {
  font-family: $fontFamily2;
  font-weight: 800;
  font-size: 2rem;
  color: #262626;
  margin: 1.5rem 0rem;
  padding-left:54px;
  padding-right:55px;
}
.name-row{
  flex-direction: column;
  min-width: 100%;
  height: 80px;
  margin-right:0px;
}
</style>
