<template>
    <v-container fluid class="h-100 text-center">
        <div>
            <img src="@/assets/svg/logo-colored.svg" class="logo" />
        </div>
        <div class="text-center body-content">
            <Loader />
            <h3 class="pt-6 text">{{this.getLoadingText()}}</h3>
        </div>
    </v-container>
</template>
<script>
import { mapActions } from 'vuex';
import Loader from '@/components/common/Loader';

const LOADER_TIMEOUT = 5 * 1000; // 5 SECONDS
export default {
  name: 'Loader',
  components: {
    Loader,
  },
  data() {
    return ({
      timer: null,
    });
  },
  methods: {
    ...mapActions('user', ['loginUser', 'getAuthInfo']),
    getLoadingText() {
      const accountType = localStorage.getItem('BS_ONBOARDING_ACCOUNT_TYPE');
      if (accountType === 'creative') {
        // eslint-disable-next-line quotes
        return `Setting up your freelancer account...`;
      }
      // eslint-disable-next-line quotes
      return `We're setting up an epic business profile for you!`;
    },
  },
  async mounted() {
    const encodedData = localStorage.getItem('BS_ONBOARDING_INFO');
    const loginType = localStorage.getItem('BS_SIGN_IN_MODE');
    if (loginType === 'SSO') {
      const { user = {} } = await this.getAuthInfo();
      this.timer = setTimeout(() => {
        localStorage.removeItem('BS_ONBOARDING_INFO');
        localStorage.removeItem('BS_ONBOARDING_EMAIL');
        localStorage.removeItem('BS_ONBOARDING_BUSINESS_TYPE');
        localStorage.removeItem('BS_SIGN_IN_MODE');
        this.$hyper.translate(user.preferredLanguage || 'en', () => {
          localStorage.setItem('app_language', user.preferredLanguage || 'en');
        });
        this.$router.push('/dashboard');
      }, LOADER_TIMEOUT);
      return null;
    }
    if (encodedData) {
      const loginInfo = JSON.parse(atob(encodedData));
      const response = await this.loginUser({
        user: {
          email: loginInfo.email,
          password: loginInfo.password,
        },
        rememberMe: true,
      });
      if (response.success) {
        this.timer = setTimeout(() => {
          localStorage.removeItem('BS_ONBOARDING_INFO');
          localStorage.removeItem('BS_ONBOARDING_EMAIL');
          localStorage.removeItem('BS_ONBOARDING_BUSINESS_TYPE');
          const { user } = response;
          this.$hyper.translate(user.preferredLanguage || 'en', () => {
            localStorage.setItem('app_language', user.preferredLanguage || 'en');
          });
          this.$router.push('/dashboard');
        }, LOADER_TIMEOUT);
      }
    } else {
      this.timer = setTimeout(() => {
        localStorage.removeItem('BS_ONBOARDING_INFO');
        localStorage.removeItem('BS_ONBOARDING_EMAIL');
        localStorage.removeItem('BS_ONBOARDING_BUSINESS_TYPE');
        this.$router.push('/login');
      }, LOADER_TIMEOUT);
    }
    return null;
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
};
</script>

<style lang="scss" scoped>
.logo {
  width: 15.625rem;
  margin-top: 1rem;
}
.body-content {
  margin-top: 200px;
  .text {
      font-family: $fontFamily1;
      font-weight: bold;
      font-size: 32px;
      text-align: center;
      color: $charcoalBlack;
  }
}
</style>
