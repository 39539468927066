<template>
  <v-container fluid class="bs-container pa-0">
    <OnboardingNavBar :showProfile=false />
      <div class="content-main">
        <div class="content-main-info">
           <v-col class="center-alignment" col-xs="12" col-sm="12" col-md="5" col-lg="8" col-xl="8">
        <v-card class="register-form-wrapper text-center" elevation="0">
          <div>
            <div class="body-content">
              <p class="register-text">
                Join as a customer or creative
              </p>
              <v-row class="userDiv">
                  <v-card class="pa-4 user-card" v-on:click="selectRole('client')"
                    :class="{selected: this.selectedRole === 'client'}">
                    <div class="pic-holder">
                      <v-avatar left tile>
                        <img src="@/assets/svg/Customer.svg" class="profile-icon" />
                      </v-avatar>
                    </div>
                    <v-card-title class="d-block title">
                      Customer
                    </v-card-title>
                    <v-card-text class="text">
                      I'm a local business owner. I need content!
                    </v-card-text>
                  </v-card>
                  <v-card class="pa-4 user-card"
                    :class="{selected: this.selectedRole === 'creative'}"
                    v-on:click="selectRole('creative')">
                    <div class="pic-holder">
                      <v-avatar left tile>
                        <img src="@/assets/svg/creative_2.svg" class="profile-icon" />
                      </v-avatar>
                    </div>
                    <v-card-title class="d-block title">
                      Freelancer
                    </v-card-title>
                    <v-card-text class="text">
                      I'm a creative professional. I want to create content.
                    </v-card-text>
                  </v-card>
              </v-row>
              <div class="content-main-actions  justify-center">
            <div class="action-container">
                <div class="continue-btn-wrapper d-flex flex-column">
                    <v-btn
                        class="continue-btn"
                        depressed
                        @click="onSubmit"
                        :disabled="isDisabled"
                    >
                        {{ submitName }}
                    </v-btn>
                </div>
            </div>
            <div class="already-mrg">
              <p class="acct">
              Already have an account?
              <router-link :to="{ name: 'Login'}" class="login">Login</router-link>
            </p>
            </div>
        </div>
            </div>
          </div>
        </v-card>
      </v-col>
        </div>
      </div>

  </v-container>
</template>

<script>
import OnboardingNavBar from './creative/OnboardingNavBar';

export default {
  name: 'Role',
  components: {
    OnboardingNavBar,
  },
  data() {
    return ({
      selectedRole: false,
    });
  },
  methods: {
    selectRole(role) {
      this.selectedRole = role;
    },
    async onSubmit() {
      await localStorage.setItem('role', this.selectedRole);
      this.$router.push({ path: `/register?onboarding_role=${this.selectedRole}` });
    },
  },
  computed: {
    submitName() {
      if (this.selectedRole === 'creative') {
        return 'Apply as Creative';
      }
      if (this.selectedRole === 'client') {
        return 'Sign Up as Customer';
      }
      return 'Create Account';
    },
    isDisabled() {
      return !['client', 'creative'].includes(this.selectedRole);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  margin: 10px;
}
.userDiv {
    margin: 0px;
    justify-content: center;
}
.bs-container {
    flex-direction: column;
    min-height: 100vh;
    background: white;
}
.content-main {
    max-width: 1290px;
    margin: auto;
    padding: 2rem;
}
.content-main-actions {
    margin-top: 3rem;
}
.content-main-inner-inner {
    display: flex;
    flex-wrap: wrap;
}
.greeting-text {
    font-family: $fontFamily2;
    font-style: normal;
    font-weight: 800;
    font-size: 46px;
    line-height: 58px;
    color: #262626;
}
.note-item {
    display: flex;
    align-items: center;
    padding: 1rem 0rem;
    .note-item-icon {
        margin-right: 1rem
    }
}

.bs-divider {
    margin: 2rem 0rem;
    border-top: 1px solid #DEDEDE;
}
.content {
    max-width: 600px;
    min-width: 400px;
}
.note-items {
    margin-top: 2rem;
}
.actions {
    margin-top: 2rem;
}
.action-container {
    display: flex;
    justify-content: center;
}
.already-mrg {
  margin-top: 2rem;
  margin-bottom: 3rem;
}
.continue-btn-wrapper{
    min-width: 270px;
    .btn-note {
        margin-top: 0.5rem;
        color: #929292;
        font-family: $fontFamily1;
    }
}
.continue-btn {
    width: 100%;
    border-radius: 1.5rem;
    height: 44px;
    padding: 0.5rem 2rem !important;
    background: $secondary1 !important;
    color: white;
    .v-btn__content {
    font-size: 16px;
    line-height: 28px;
    }
}
.content-left {
    flex: 1;
}
.content-right {
    min-width: 400px;
}
.illustration-container {
    height: 100%;
    background: rgb(229, 229, 229);
    min-height: 400px;
}
.illustration{
}
.login {
    color: #8066FF
}
@media screen and (max-width: 900px) {
    .content-main-inner-inner {
        flex-direction: column;
        align-items: center;
    }
    .content-main-actions {
        justify-content: center !important;
    }
}
.v-card__text {
  padding: 0px;
}
.register-page {
  .back-div {
    cursor: pointer;
    display: flex;
    height: fit-content;
    margin-left: 49px;
    .back-icon {
      width: 24px;
      height: 24px;
    }
    .back-text {
      padding-left: 6px;
      font-family: $fontFamily1;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      color: #000000;
    }
  }
  .logo-div {
    margin-right: 10%;
  }
}
.user-card {
  box-shadow: none !important;
  max-width: 256px;
  height: 296px;
  border: 1px solid #DEDEDE;
}
.pic-holder {
  width: auto;
  height: 133px;
  .v-avatar {
    height: 100% !important;
    width: 100% !important;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}
.title {
  padding-top: 1.25rem !important;
  padding-bottom: 3px;
  font-family: $fontFamily1 !important;
  color: #000000;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
}
.text {
  font-family: $fontFamily1;
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
text-align: center;
  color: #8c8c8c !important;
  overflow:hidden;
  min-height: 2.5rem;
  line-height: 19px;
  padding-top:15px;
}
.register-form-wrapper {
  z-index: 20;
  padding: 0.75rem 0px;
  background: none;
  width: 812px;
  height: 650px;
  background: #FFFFFF;

border-radius: 8px;
}
.center-alignment {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-bar {
  margin-top: 1rem;
  .logo {
    width: 15.625rem;
  }
}
.register-text {
  margin-top: 50px;
  margin-bottom: 4rem;
  color: #262626;
font-family: Mulish;
font-size: 46px;
font-weight: 800;
line-height: 58px;
letter-spacing: 0em;
text-align: center;

}
.aact {
font-family: $fontFamily1;
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
}
.register-button {
  min-width: auto;
  width: 12.5rem;
  height: 3rem !important;
  border-radius: 1.5625rem;
  box-shadow: none;
  background-color: $neutral12 !important;
  margin-top: 8px;
  ::v-deep .v-btn__content {
    font-family: $fontFamily1;
    text-align: center;
    color: #ffffff;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
  }
}
.selected {
 background: #FFFFFF;
/* 65% Electric Purple */

outline: 6px solid #AC9CFF;
border-radius: 4px;
}
::v-deep .v-card:not(.v-card--flat):not(.v-picker--time):not(.v-picker--date) {
  border-radius: 4px !important;
}
@media (max-width: 1330px) {
  .divider {
    display: none;
  }
}
@media (min-width: 1367px) {
  .divider {
    max-width: 100%;
    margin: auto;
    margin-bottom: 115px;
    border: solid 2px rgba(223, 223, 223, 0.15);
    background: rgba(45, 45, 45, 0.15);
  }
}
@media (min-width: 601px) {
   .register-form-wrapper {
    height: 100%;
    padding: 0px;
    border: 1px solid #DEDEDE;
box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08) !important;
  }
}
@media (min-width: 601px) and (max-width: 960px) {
  .register-form-wrapper {
    height: 100%;
    padding: 0px;
    border: 1px solid #DEDEDE;
box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08) !important;
  }
  .back-box {
    // justify-content: flex-end;
    .back-div {
      margin-left: 20px !important;
      .back-text {
        display: none;
      }
    }
  }
}
@media (max-width: 600px) {
  .already-mrg {
  display: none;
}
  .continue-btn{
    width:270px;
  }
  .content-main-actions {
    margin-top: 2rem;
  }
  .register-text {
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem;
    font-size: 30px;
    line-height: 38px
  }
  .register-button {
    margin-top: 10px;
  }
  .register-form-wrapper {
    height: 100%;
    padding: 0px;
    box-shadow: 0 !important;
    border: 0px;
  }
  .back-box {
    // justify-content: flex-end;
    .back-div {
      margin-left: 10px !important;
      .back-text {
        display: none;
      }
    }
  }
}
@media only screen and (min-device-width: 961px) and (max-device-width: 1340px) {
  .register-form-wrapper {
    display: flex;
    flex-direction: column;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    border: 1px solid #DEDEDE;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08) !important;
  }
}
</style>
